import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
// const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
// const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
// const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
// const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const NFTs = () => import(/* webpackChunkName: "common" */ "@/pages/NFTs.vue");
const NFTs_01 = () => import(/* webpackChunkName: "common" */ "@/pages/NFTs_01.vue");
const TestSandbox = () => import(/* webpackChunkName: "common" */ "@/pages/TestSandbox.vue");
const ManageNfts = () => import(/* webpackChunkName: "common" */ "@/pages/ManageNfts.vue");
const TradeNfts = () => import(/* webpackChunkName: "common" */ "@/pages/TradeNfts.vue");
const Information = () => import(/* webpackChunkName: "common" */ "@/pages/Information.vue");
const Roadmap = () => import(/* webpackChunkName: "common" */ "@/pages/learn.vue");
const Checklists = () => import(/* webpackChunkName: "common" */ "@/pages/checkLists.vue");
const Bounties = () => import(/* webpackChunkName: "common" */ "@/pages/bounties.vue");
const Token = () => import(/* webpackChunkName: "common" */ "@/pages/token.vue");
const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "MINT",
        component: Dashboard
      },
      {
        path: "profile",
        name: "profile",
        component: Profile
      },
      // {
      //   path: "notifications",
      //   name: "notifications",
      //   component: Notifications
      // },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },
      {
        path: "token",
        name: "token",
        component: Token
      },
      // {
      //   path: "maps",
      //   name: "maps",
      //   component: Maps
      // },
      // {
      //   path: "typography",
      //   name: "typography",
      //   component: Typography
      // },
      // {
      //   path: "table-list",
      //   name: "table-list",
      //   component: TableList
      // },
      {
        path: "nfts",
        name: "nfts",
        component : NFTs
      },
      {
        path: "roadmap",
        name: "roadmap",
        component : Roadmap
      },
      {
        path: "nfts_01",
        name: "nfts_01",
        component : NFTs_01
      },
      {
        path: "managenfts",
        name: "managenfts",
        component : ManageNfts
      },
      {
        path: "tradenfts",
        name: "tradenfts",
        component : TradeNfts
      },
      {
        path: "information",
        name: "information",
        component : Information
      },
      {
        path: "checklists",
        name: "checklists",
        component : Checklists
      },
      {
        path: "bounties",
        name: "bounties",
        component : Bounties
      },

      {
        path: "testsandbox",
        name: "testsandbox",
        component : TestSandbox
      }

    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
