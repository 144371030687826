<template>
  <div class="hello">
    Elemint Owned: {{ balance }}
  </div>
</template>

<script>

export default {
  name: 'TokenBalance',
  computed: {
    balance(){
        let tokenB = BigInt(this.$store.state.elemintTokenBalance) / BigInt(10**18) ;
        return  tokenB;
    }
  }
}
</script>